// extracted by mini-css-extract-plugin
export var ArtistDescription = "Noc-module--ArtistDescription--ib9CC";
export var ArtistInfos = "Noc-module--ArtistInfos--zs-ul";
export var ButtonWrapper = "Noc-module--ButtonWrapper --zujyX";
export var CardWrapper = "Noc-module--CardWrapper--qf1eN";
export var CarrouselWrapper2 = "Noc-module--CarrouselWrapper2--CQFUi";
export var Citations = "Noc-module--Citations--V1YsI";
export var DescriptionWrapper = "Noc-module--DescriptionWrapper--X+biB";
export var ImageWrapper = "Noc-module--ImageWrapper--g+k0c";
export var LinkWrapper = "Noc-module--LinkWrapper--Cmg6t";
export var MobileProtrait = "Noc-module--MobileProtrait--2S2Mt";
export var More = "Noc-module--More--Nuq0z";
export var MoreButton = "Noc-module--MoreButton--f4Cm0";
export var NameWrapper = "Noc-module--NameWrapper--f1-Yi";
export var PdpWrapper = "Noc-module--PdpWrapper--xR8ej";
export var PhotosWrapper = "Noc-module--PhotosWrapper--5xlwg";
export var ProfilWrapper = "Noc-module--ProfilWrapper--cYhGI";
export var TitleWrapper = "Noc-module--TitleWrapper--fUkoe";
export var Wrapper = "Noc-module--Wrapper--31Q6l";