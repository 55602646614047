import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./Noc.module.css";
import { withPrefix } from "gatsby"

import Title from "./../../../../components/Title";
import Carousel from 'react-multi-carousel';
import Button from "./../../../../components/Button";
import Pdp from '../../../../res/noc/portrait.png'
import { Helmet } from "react-helmet";
import PastPresent1 from "../../../../res/noc/past-present-1.png"
import PastPresent2 from "../../../../res/noc/past-present-2.png"
import PastPresent3 from "../../../../res/noc/past-present-3.jpeg"
const infos ={
  path: "noc",
  name: "NOC 167",
  description: "NOC 167 est un artiste de la deuxième génération du \"Wild Style\" - un lettrage plus tridimensionnel, fluide et dynamique. Son art a explosé sur la scène du Graffiti, qui gagnait en notoriété à la fin des années 1970 et au début des années 1980 à New-York. Ses oeuvres sont apparues sur plus de 200 wagons de métro, sur diverses fresques murales et dans le générique du film culte Wild Style de Charlie Ahearn. NOC 167 a grandi dans le Bronx et a été initié à l'art par les bandes dessinées, les jeux vidéo et les cours de la School of Visual Arts. Son travail a été exposé, entre autres, au New Museum de New York, à la Fun Gallery de New York et à la Sidney Janis Gallery de New York.",
  pdp: Pdp,
  alt_pdp: "Photo de profil de Noc 167",
  photos: [
    { src: PastPresent1, name: "Dondi White" },
    { src: PastPresent2, name: "Dondi White" },
    { src: PastPresent3, name: "Dondi White" },
  ],
  citations: [
  ]
};


const isBrowser = typeof window !== "undefined"

const Noc = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <Helmet>
        {
            isBrowser ? window.onscroll = function(e){
              var sel = document.querySelector('#f')
              var end = document.querySelector('#end').offsetTop - 450;
              if (window.scrollY > end) {
                  sel.style.position = 'absolute';
                  sel.style.top = end.toString()+ "px";
              } else {
                  sel.style.position = 'fixed';
                  sel.style.top = 0;
              }
            } : null
          }
      </Helmet>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>NOC 167 <h2 style={{paddingLeft: "16px"}}>(Melvin Samuels Jr - Américain)</h2></h1>
          <h3 className={NameWrapper} >Né en 1960</h3>
          <img className={MobileProtrait} src={infos.pdp} alt={infos.alt_pdp} />
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <br />
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />
            <ul className={ArtistInfos}>
                           <br />
             
              <p style={{fontWeight: "bolder"}}>EXHIBITIONS</p>
              <br />
              <p style={{fontWeight: "bold"}}>2022</p>
              <p>- Graffiti in New-York, The Groninger Museum (Netherlands), 9 novembre 2021 - 2 octobre 2022 </p>
              <br />
              <p style={{fontWeight: "bold"}}>2021</p>
              <p>- Ghost DNA - exposition d’ouverture, Ghost galerie, Paris, France, 7 Juillet - 4 Septembre, 2021</p>
              <br />
              <p style={{fontWeight: "bold"}}>2019</p>
              <p>- Group Show, Beyond the Streets Gallery - New-York, 2019</p>
              <br />
              <p style={{fontWeight: "bold"}}>1985</p>
              <p>- Graffiti Paintings by A-One, Noc + Toxic, Sidney Janis Gallery, New-York, 14 mars au 6 avril 198</p>
              <br />
              <p style={{fontWeight: "bold"}}>1981</p>
              <p>- Graffiti : Above Ground, New York US, 1981</p>
              <br />
              <p style={{fontWeight: "bold"}}>1983</p>
              <p id="end">- GAS (Graffiti Art Success for America), produced by Johnny CRASH Matos, Fashion Moda, 1980</p>
            </ul>
        </div>
      </div>
      <div className={PhotosWrapper}>
        <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
          {/* <Carrousel images={infos.photos} />
           */}
           <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/fr/contactez-nous";
                }
              }}
            >
              Contactez-nous
            </Button>
        </div>
    </div>
  );
};

export default Noc;